import { useCallback } from 'react';
import useApi, { Method } from 'src/hooks/useApi';
import { QueryResult } from './useAnalytics';

export interface TStatistics {
    fetch: <T>(params: {
        campaignID: number;
        timeframe?: number;
        interval?: string;
        start?: string;
        end?: string;
        aggregated?: boolean;
    }) => Promise<QueryResult<T>[]>;
}

const useStatistics = (): TStatistics => {
    const { request } = useApi();

    const fetch = useCallback(
        async <T>({
            campaignID,
            timeframe,
            interval,
            start,
            end,
            aggregated = false,
        }: {
            campaignID: number;
            timeframe?: number;
            interval?: string;
            start?: string;
            end?: string;
            aggregated?: boolean;
        }): Promise<QueryResult<T>[]> => {
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    stat: 'views',
                    timeframe,
                    interval,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,
                    aggregated,
                },
            });
            return response.data.Results.views;
        },
        [request],
    );

    return {
        fetch,
    };
};

export default useStatistics;
