import { useContext, useRef, useState } from 'react';
import { Stack, Button, Grid, Popover } from '@mui/material';
import { Color } from '../../../../Color';
import CategoriesChart from './charts/CategoriesChart';
import TopMediaChart from './charts/TopMediaChart';
import ClickChart from './charts/ClickChart';
import ViewsHistoryChart from './charts/ViewsHistoryChart';
import { Interval, Timeframe } from 'src/hooks/useAnalytics';
import ScanLog from './charts/ScanLog';
import LocationChart from './charts/LocationChart';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateTime } from 'luxon';
import { DateRange } from '@mui/x-date-pickers-pro';

export interface TimeframeAndInterval {
    label: string;
    timeframe: Timeframe;
    interval: Interval;
    days: number;
    unit: 'hours' | 'days' | 'weeks' | 'months';
}

const timeframeAndIntervals = [
    {
        label: '1D',
        timeframe: Timeframe.This1Days,
        interval: Interval.Hourly,
        days: 1,
        unit: 'hours' as const,
    },
    {
        label: '3D',
        timeframe: Timeframe.This3Days,
        interval: Interval.Daily,
        days: 3,
        unit: 'days' as const,
    },
    {
        label: '5D',
        timeframe: Timeframe.This5Days,
        interval: Interval.Daily,
        days: 5,
        unit: 'days' as const,
    },
    {
        label: '1W',
        timeframe: Timeframe.This7Days,
        interval: Interval.Daily,
        days: 7,
        unit: 'days' as const,
    },
    {
        label: '1M',
        timeframe: Timeframe.This30Days,
        interval: Interval.Daily,
        days: 30,
        unit: 'days' as const,
    },
    {
        label: '3M',
        timeframe: Timeframe.This90Days,
        interval: Interval.Weekly,
        days: 90,
        unit: 'weeks' as const,
    },
    {
        label: '6M',
        timeframe: Timeframe.This180Days,
        interval: Interval.Weekly,
        days: 180,
        unit: 'weeks' as const,
    },
    {
        label: 'YTD',
        timeframe: Timeframe.ThisYear,
        interval: Interval.Monthly,
        // TODO: This should be current DoY
        days: 365,
        unit: 'months' as const,
    },
    // {
    //     label: 'ALL',
    //     value: Timeframe.All,
    // },
];

interface AnalyticsTopProps {
    campaignID: number;
    campaignCreated: number | undefined;
}

export function AnalyticsTop({ campaignID, campaignCreated }: AnalyticsTopProps) {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const [currentTimeframeAndInterval, setCurrentTimeframeAndInterval] = useState<TimeframeAndInterval | undefined>(
        timeframeAndIntervals[3],
    );
    const [useCustom, setUseCustom] = useState(false);
    const now = DateTime.now();
    const [range, setRange] = useState<DateRange<DateTime>>([now.minus({ days: 7 }).startOf('day'), now.endOf('day')]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const campaignCreatedTimestamp = (campaignCreated || 0) * 1000;
    const diffInDays = Math.ceil((now.toMillis() - campaignCreatedTimestamp) / (1000 * 60 * 60 * 24));

    const isDisabledTimeframe = (value: Timeframe) => {
        if (!campaignCreated) return false;
        switch (value) {
            case Timeframe.This30Days:
                return diffInDays < 7;
            case Timeframe.This90Days:
                return diffInDays < 30;
            case Timeframe.This180Days:
                return diffInDays < 90;
            case Timeframe.ThisYear:
                return diffInDays < 180;
            default:
                return false;
        }
    };

    const timeOptionsRef = useRef<HTMLDivElement>(null!);

    return (
        <>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 3,
                }}
                ref={timeOptionsRef}
            >
                <Stack direction="row">
                    <Stack direction="row" spacing={1}>
                        {timeframeAndIntervals.map(item => {
                            return (
                                <Button
                                    key={item.label}
                                    disabled={isDisabledTimeframe(item.timeframe)}
                                    sx={{
                                        backgroundColor:
                                            currentTimeframeAndInterval === item ?
                                                Color.PrimaryLavender
                                            :   Color.LightLavenderLightMode,
                                        color:
                                            currentTimeframeAndInterval === item ? Color.White
                                            : darkMode ? Color.White
                                            : Color.Black,
                                        borderRadius: 1,
                                    }}
                                    onClick={() => {
                                        setUseCustom(false);
                                        setCurrentTimeframeAndInterval(item);
                                    }}
                                >
                                    {item.label}
                                </Button>
                            );
                        })}

                        <Button
                            key="Custom"
                            sx={{
                                backgroundColor: useCustom ? Color.PrimaryLavender : Color.LightLavenderLightMode,
                                color:
                                    useCustom ? Color.White
                                    : darkMode ? Color.White
                                    : Color.Black,
                                borderRadius: 1,
                            }}
                            onClick={event => {
                                setAnchorEl(event.currentTarget);
                                setCurrentTimeframeAndInterval(undefined);
                                setUseCustom(true);
                            }}
                        >
                            Custom
                        </Button>
                    </Stack>
                </Stack>

                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <TopMediaChart
                            campaignID={campaignID}
                            timeframeAndInterval={currentTimeframeAndInterval}
                            start={range[0]?.toISO({ includeOffset: false }) ?? undefined}
                            end={range[1]?.toISO({ includeOffset: false }) ?? undefined}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    gap={2}
                    sx={{
                        flexWrap: { md: 'nowrap' },
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                        }}
                    >
                        <LocationChart campaignID={campaignID} timeframe={currentTimeframeAndInterval?.timeframe} />
                    </Grid>
                    <Grid item container xs={12} md={8} gap={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                            }}
                        >
                            <ViewsHistoryChart
                                campaignID={campaignID}
                                timeframeAndInterval={currentTimeframeAndInterval}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                            }}
                        >
                            <CategoriesChart
                                campaignID={campaignID}
                                timeframe={currentTimeframeAndInterval?.timeframe}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md
                            sx={{
                                p: 2,
                                borderRadius: 2,
                                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderDarkMode,
                            }}
                        >
                            <ClickChart campaignID={campaignID} timeframe={currentTimeframeAndInterval?.timeframe} />
                        </Grid>
                    </Grid>
                </Grid>

                <ScanLog
                    campaignID={campaignID}
                    timeframe={currentTimeframeAndInterval?.timeframe}
                    timeOptionsRef={timeOptionsRef}
                />
            </Stack>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setAnchorEl(null);
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DateRangeCalendar
                        calendars={2}
                        value={range}
                        onChange={newValue => {
                            setRange(newValue);
                        }}
                    />
                </LocalizationProvider>
                <Stack
                    direction="row"
                    sx={{
                        paddingBottom: 2,
                        paddingRight: 2,
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            setAnchorEl(null);
                        }}
                    >
                        Done
                    </Button>
                </Stack>
            </Popover>
        </>
    );
}

export function AnalyticsBottom() {
    return null;
}
