import { Box, Stack, Typography } from '@mui/material';
import { useState, useCallback, useEffect, useContext } from 'react';
import MediaContext, { Image, TMedia } from 'src/contexts/MediaContext';
import ResponsiveLineChart from './ResponsiveLineChart';
import useAnalytics, { Interval, QueryResult } from 'src/hooks/useAnalytics';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import EnvContext, { TEnv } from 'src/contexts/EnvContext';
import useStatistics from 'src/hooks/useStatistics';
import { randomColor } from 'src/util/color';
import { TimeframeAndInterval } from '../Analytics';

interface TopMediaChartProps {
    campaignID: number;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;
}

export default function TopMediaChart({ campaignID, timeframeAndInterval, start, end }: TopMediaChartProps) {
    interface TopMedia {
        id: string;
        image?: Image;
        color: string;
        totalViews: number;
        data: any[];
    }

    const { query } = useAnalytics();
    const { fetch } = useStatistics();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();

    const { fetchImageList } = useContext(MediaContext) as TMedia;

    const [topMedia, setTopMedia] = useState<TopMedia[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [chartsLoaded, setChartsLoaded] = useState(false); // Add boolean state for charts loaded
    const { client } = useContext(EnvContext) as TEnv;

    interface ImageViewsPerInterval {
        // Keen
        image_id: string;
        result: number;
        // IRCODE
        imageID: string;
        count: number;
    }

    const fetchAnalytics = useCallback(() => {
        console.log('TopMediaChart fetchAnalytics', timeframeAndInterval, start, end);
        return new Promise<any[]>((resolve, reject) => {
            console.log('fetching...');
            fetch<ImageViewsPerInterval[]>({
                campaignID,
                timeframe: timeframeAndInterval?.days,
                interval: timeframeAndInterval?.unit ?? 'days',
                start,
                end,
            })
                .then(async results => {
                    // console.log('irdb results', results);

                    // Backfill results for images with days with no views
                    // const image_ids = images.map(image => image.imageID);
                    // const image_ids = results
                    //     .reduce((acc: string[], result: any) => {
                    //         // console.log('result', result);
                    //         const imageIDs = result.value.map((v: any) => v.imageID);
                    //         return acc.concat(imageIDs);
                    //     }, [])
                    //     .unique();
                    // // console.log('image_ids', image_ids);

                    // results = results.map((result: QueryResult<ImageViewsPerInterval[]>, index: number) => {
                    //     for (const image_id of image_ids) {
                    //         if (!(result.value as ImageViewsPerInterval[]).find(r => r.image_id === image_id)) {
                    //             (result.value as ImageViewsPerInterval[]).push({
                    //                 image_id,
                    //                 result: 0,
                    //             });
                    //         }
                    //     }

                    //     return result;
                    // });
                    // console.log('Backfilled results', results);

                    // Intermediate step for pivoting data
                    const resultsByImage = results.reduce(
                        (acc: any, result: QueryResult<ImageViewsPerInterval[]>) => {
                            for (const image of result.value as ImageViewsPerInterval[]) {
                                if (!acc[image.imageID]) {
                                    acc[image.imageID] = [];
                                }
                                acc[image.imageID].push({
                                    // @ts-ignore
                                    timeframe: result.timeframe?.start ?? '',
                                    result: image.count,
                                });
                            }

                            return acc;
                        },
                        {} as Record<string, ImageViewsPerInterval[]>,
                    );
                    // console.log('resultsByImage', resultsByImage);

                    let formattedResults: TopMedia[] = Object.keys(resultsByImage)
                        .map((image_id: string): TopMedia => {
                            return {
                                id: image_id,
                                color: randomColor(image_id),
                                totalViews: resultsByImage[image_id].reduce((acc: number, r: any) => acc + r.result, 0),
                                data: resultsByImage[image_id].map((r: any, index: number) => {
                                    return {
                                        x: r.timeframe,
                                        y: r.result,
                                    };
                                }),
                            };
                        })
                        .filter((media: TopMedia) => media.totalViews > 0)
                        .sort((a: TopMedia, b: TopMedia) => b.totalViews - a.totalViews);
                    // console.log('formattedResults', formattedResults);

                    const imageList = await fetchImageList(formattedResults.map((media: TopMedia) => media.id));
                    formattedResults = formattedResults
                        .filter(media => imageList.some(image => image.imageID === media.id))
                        .map((media: TopMedia) => {
                            media.image = imageList.find(image => image.imageID === media.id) as Image;
                            if (!media.image.metaContent) {
                                media.image.metaContent = { title: 'Untitled' };
                            } else if (!media.image.metaContent.title) {
                                media.image.metaContent.title = 'Untitled';
                            }
                            return media;
                        })
                        .slice(0, 6);

                    console.log('i formattedResults', formattedResults);
                    resolve(formattedResults);
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {
                    setChartsLoaded(true);
                });
        });

        // return new Promise<any[]>((resolve, reject) => {
        //     query<ImageViewsPerInterval[]>(
        //         // Type.CountUnique,
        //         Type.Count,
        //         {
        //             event_collection: Event.ImageViewed,
        //             timeframe,
        //             interval,
        //             filters: [
        //                 {
        //                     property_name: Parameter.CampaignID,
        //                     operator: 'eq',
        //                     property_value: campaignID,
        //                 },
        //             ],
        //             group_by: ['image_id'],
        //         },
        //     )
        //         .then(async results => {
        //             console.log('Raw results', results);

        //             // Backfill results for images with days with no views
        //             // const image_ids = images.map(image => image.imageID);
        //             // results = results
        //             //     .map((result: QueryResult<ImageViewsPerInterval[]>, index: number) => {
        //             //         for (const image_id of image_ids) {
        //             //             if (!(result.value as ImageViewsPerInterval[]).find(r => r.image_id === image_id)) {
        //             //                 (result.value as ImageViewsPerInterval[]).push({
        //             //                     image_id,
        //             //                     result: 0,
        //             //                 });
        //             //             }
        //             //         }

        //             //         return result;
        //             //     });
        //             // console.log('Backfilled results', results);

        //             // Intermediate step for pivoting data
        //             const resultsByImage = results.reduce(
        //                 (acc: any, result: QueryResult<ImageViewsPerInterval[]>) => {
        //                     for (const image of result.value as ImageViewsPerInterval[]) {
        //                         if (!acc[image.image_id]) {
        //                             acc[image.image_id] = [];
        //                         }
        //                         acc[image.image_id].push({
        //                             // @ts-ignore
        //                             timeframe: result.timeframe?.start ?? '',
        //                             result: image.result,
        //                         });
        //                     }

        //                     return acc;
        //                 },
        //                 {} as Record<string, ImageViewsPerInterval[]>,
        //             );
        //             // console.log('resultsByImage', resultsByImage);

        //             let formattedResults: TopMedia[] = Object.keys(resultsByImage)
        //                 .map((image_id: string): TopMedia => {
        //                     return {
        //                         id: image_id,
        //                         color: randomColor(image_id),
        //                         totalViews: resultsByImage[image_id].reduce((acc: number, r: any) => acc + r.result, 0),
        //                         data: resultsByImage[image_id].map((r: any, index: number) => {
        //                             return {
        //                                 x: r.timeframe,
        //                                 y: r.result,
        //                             };
        //                         }),
        //                     };
        //                 })
        //                 .filter((media: TopMedia) => media.totalViews > 0)
        //                 .sort((a: TopMedia, b: TopMedia) => b.totalViews - a.totalViews);
        //             // console.log('formattedResults', formattedResults);

        //             const imageList = await fetchImageList(formattedResults.map((media: TopMedia) => media.id));
        //             formattedResults = formattedResults
        //                 .filter(media => imageList.some(image => image.imageID === media.id))
        //                 .map((media: TopMedia) => {
        //                     media.image = imageList.find(image => image.imageID === media.id) as Image;
        //                     if (!media.image.metaContent) {
        //                         media.image.metaContent = { title: 'Untitled' };
        //                     } else if (!media.image.metaContent.title) {
        //                         media.image.metaContent.title = 'Untitled';
        //                     }
        //                     return media;
        //                 })
        //                 .slice(0, 6);

        //             resolve(formattedResults);
        //         })
        //         .catch((error: any) => {
        //             reject(error);
        //         })
        //         .finally(() => {
        //             setChartsLoaded(true);
        //         });
        // });
    }, [timeframeAndInterval, start, end]);
    // fetch, campaignID, timeframeAndInterval, start, end, fetchImageList

    useEffect(() => {
        // console.log('TopMediaChart useEffect', campaignID, images);
        // if (!campaignID || !images || images.length === 0) {
        //     return;
        // }

        if (!campaignID) {
            return;
        }

        setChartsLoaded(false);

        fetchAnalytics()
            .then(results => {
                if (client === 'brandweek') {
                    results = results.map(result => {
                        if (!result!.image!.imageUrl) {
                            result.image.imageUrl = '/images/clients/brandweek_banner.svg';
                        }
                        return result;
                    });
                }
                setTopMedia(results);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [campaignID, images, fetchAnalytics]);

    return (
        <Stack direction="column">
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                Top Media
            </Typography>

            {!chartsLoaded || !topMedia.length ?
                <Stack
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 300,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '1.2em',
                            fontWeight: 400,
                            lineHeight: '1.5em',
                            textAlign: 'center',
                        }}
                    >
                        {!chartsLoaded ?
                            'Loading...'
                        :   'There is currently no data available for this time period. Please try another view.'}
                    </Typography>
                </Stack>
            :   <ResponsiveLineChart data={topMedia} interval={timeframeAndInterval?.interval ?? Interval.Daily} />}

            <Stack
                direction="row"
                sx={{
                    flexWrap: 'wrap',
                }}
            >
                {topMedia.map((media: TopMedia, index: number) => {
                    return (
                        media.image && (
                            <Stack
                                key={media.id}
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: 'center',
                                    width: '50%',
                                    overflow: 'hidden',
                                    borderBottom: '1px solid rgba(255, 255, 255, .1)',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigateAndScrollToTop(`/dashboard/ircodes/${media.id}`);
                                }}
                            >
                                <Box
                                    component="img"
                                    src={media.image?.imageUrl ?? ''}
                                    sx={{
                                        flexShrink: 0,
                                        flexBasis: 82,
                                        width: 82,
                                        height: 82,
                                        objectFit: 'cover',
                                        visibility: media.image ? 'visible' : 'hidden',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '16px',
                                        letterSpacing: '0.15em',
                                        textAlign: 'left',
                                    }}
                                >
                                    {index + 1}
                                </Typography>

                                <Box
                                    sx={{
                                        flexShrink: 0,
                                        width: 3,
                                        height: 16,
                                        backgroundColor: media.color,
                                    }}
                                />

                                <Typography
                                    sx={{
                                        flexGrow: 1,

                                        fontFamily: 'Nunito Sans',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {media.image.metaContent?.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        flexShrink: 0,

                                        fontFamily: 'Nunito Sans',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0.4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {media.totalViews}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        ml: '.4em !important',
                                        pr: 2,
                                        opacity: 0.6,
                                    }}
                                >
                                    view{media.totalViews === 1 ? '' : 's'}
                                </Typography>
                            </Stack>
                        )
                    );
                })}
            </Stack>
        </Stack>
    );
}
